import { createGlobalStyle, css } from 'styled-components';
import hexToRGBA from '@plug/helpers/hex-to-rgba';
import { getContrastingColor } from '@plug/helpers/get-contrasting-color';

export default createGlobalStyle<{ customization: ColorCustomization }>`
${({ customization: colors }): any => {
  const colorSelectRGB = colors?.buttons ? hexToRGBA(`${colors?.buttons}`, 20) : 'inherit';
  const colorSelectRGBHovered = colors?.buttons ? hexToRGBA(`${colors?.buttons}`, 30) : 'inherit';

  const textHeaderColor = colors?.header ? getContrastingColor(colors?.header) : null;
  const textButtonColor = colors?.buttons ? getContrastingColor(colors?.buttons) : null;

  return css`
    ${
      colors?.buttons
        ? `
        .button-small .button-label {
          font-size: 14px!important;
          line-height: 20px !important;
        }

        .select-item[data-state=checked] {
          background: ${colorSelectRGB} !important;

          &:hover {
            background: ${colorSelectRGBHovered} !important;
          }
        }

        .day-is-selected {
          &::before {
            background-color: ${colors?.buttons} !important;
          }
        }

        .filter-by-period {
          .round-button-active {
            background-color: ${colors?.buttons} !important;
            color: ${textButtonColor}!important;
          }
        }

        .pager-item-active {
          background-color: ${colors?.buttons} !important;
          color: ${textButtonColor}!important;
        }

        .toggle input:not(:disabled):checked+.toggle-slider {
          background-color: ${colors?.buttons} !important;
          color: ${textButtonColor}!important;
        }

        .ds-button-primary:not(:disabled),
        .link-generator:not(:disabled),
        .button-filled:not(:disabled) {
          border: 1px solid transparent !important;
          background-color: ${colors?.buttons} !important;
          color: ${textButtonColor}!important;
          transition: all .5s;

          span { 
            color: ${textButtonColor}
          }

          path, svg {
            stroke: ${textButtonColor} !important;
            fill: transparent !important;
          }

          .loading-button {
             background-color: ${textButtonColor} !important;
          }

          &:hover:not(:disabled) {
            border: 1px solid ${colors.buttons} !important;
            color: ${colors.buttons} !important;
            background-color: white !important;

            path, svg {
              stroke: ${colors?.buttons} !important;
              fill: transparent !important;
            }

            span { 
              color: ${colors?.buttons}
            }
          }
        }

        .ds-pagination-item-active {
          border: 1px solid transparent!important;
          background-color: ${colors?.buttons} !important;
          transition: all .5s;
          color: ${textButtonColor}!important;

          &:hover {
            border: 1px solid ${colors.buttons} !important;
            color: ${textButtonColor}!important;;
            background-color: ${colors.buttons}!important;
          }
        }

        .ds-pagination-item {
          border: 1px solid ${colors?.buttons}!important; !important;
          background-color: white!important;
          color: ${textButtonColor}!important;
          transition: all .5s;

          &:hover {
            border: 1px solid ${colors.buttons} !important;
            color: ${textButtonColor}!important;
            background-color: ${colors.buttons}!important;
          }
        }
        
        .ds-pagination-button {
          border-color: ${colors?.buttons} !important;
          color: ${textButtonColor}!important;

          svg, path {
            stroke: ${textButtonColor}!important;
            fill: transparent !important;
          }

          &:hover:not(:disabled) {
            background-color: ${colors?.buttons} !important;
            border-color: ${colors?.buttons} !important;
            color: ${textButtonColor}!important;

            svg, path {
              stroke: ${textButtonColor}!important;
              fill: transparent !important;
            }
          }

          &:focus:not(:disabled) {
            background-color: ${colors?.buttons} !important;
            border-color: ${colors?.buttons} !important;
        
            path {
              stroke: ${textButtonColor}!important;
              fill: transparent !important;
            }
          }

          &:disabled {
            background-color: #eeeeed!important
        
            path {
              stroke: #c8ccd5!important;
              fill: transparent !important;
            }
          }
        }
    `
        : ''
    }

    ${
      colors?.header
        ? `.header {
                background-color: ${colors?.header} !important;
                
                .user-name-header {
                  color: ${textHeaderColor} !important; 
                }
              }`
        : ''
    }

    ${
      colors?.spinner
        ? `
        .ds-spinner {
          path {
            fill: ${colors?.spinner} !important;
          }
        }
    `
        : ''
    }
    
    ${
      colors?.highlightText
        ? `
      .ds-highlight-text {
        color: ${colors?.highlightText} !important;
      }
      
      .ds-link {
        color: ${colors?.highlightText} !important;
      }

      .breadcrumb-item a {
        color: ${colors?.highlightText} !important;
      }
      
      .ds-tab-item {
        color: ${colors?.highlightText} !important;

        &--selected {
          border-color: ${colors?.highlightText} !important;
        }
      }
    `
        : ''
    }
    
    ${
      colors?.icons
        ? `
      .ds-icon:not(.ds-envelope):not(.ds-block):not(.ds-hourglass):not(.ds-instagramColor):not(.ds-messenger):not(.ds-whatsapp):not(.ds-collumns):not(.ds-check):not(.ds-chevronLeft):not(.ds-chevronRight) {
        path {
          stroke: ${colors?.icons} !important;
          fill: transparent !important;
        }
      }
  `
        : ''
    }

    @media screen and (max-width: 768px) {
      .pager {
        .pager-button {
          height: 26px;
          width: 26px;
          padding: 0px;
        }

        .pager-item {
          height: 26px;
          width: 26px;
          padding: 0px;
        }
      }
    } 
  `;
}}
`;
