import React, { useEffect } from 'react';
import {
  NavigationMenuContextInitialValues,
  useNavigationMenuContext,
} from '@plug/contexts/NavigationMenuContext';
import { Icon, iIconTypes, Typography } from '@gohubly/design-system';
import { Flex } from 'rebass';
import {
  NavSidebarMenuItemI,
  NavSidebarMenuItemWithSubItemI,
} from './nav-sidebar-menu-item-list.interface';
import { MenuItem, MenuSubItem, MenuSubItemContainer, MenuSubItemLine } from './style';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '@plug/contexts/auth-context';
import { useQuery } from '@apollo/client';
import {
  MenuTreeItem,
  MenuTreeQuery,
  MenuTreeVariables,
  MENU_TREE,
} from '@plug/graphql/queries/menu-tree';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { OrganizationStore } from '@plug/redux/store';
import { useTranslation } from 'react-i18next';
import { GET_USER_IN_ORGANIZATION_SERVICE } from '@plug/domains/organization/graphql/queries/get-user-in-organization-service';
import { Tooltip } from '@monitfy/ds';
import useIsOrganizationAdmin from '@plug/hooks/use-is-organization-admin';
import { maxWidth, padding } from 'styled-system';
import { ALLOWED_DOMAINS_CAMPAIGN } from '@plug/config/consts';

const ICONS_RELATION: { [key: string]: iIconTypes } = {
  'hubly-dashboard.sidebar.overview': 'grid',
  'hubly-dashboard.sidebar.orders': 'bag',
  'hubly-dashboard.sidebar.sale.name': 'percent',
  'hubly-dashboard.sidebar.signatures': 'bag',
  'hubly-dashboard.sidebar.member-insights': 'lightning',
  'hubly-dashboard.sidebar.finantial.name': 'cashSign2',
  'hubly-dashboard.sidebar.settings.name': 'settings',
  'hubly-dashboard.sidebar.tools.name': 'app',
  'hubly-dashboard.sidebar.finantial.mary-kay.name.member': 'cashSign2',
  'hubly-dashboard.sidebar.finantial.mary-kay.name.admin': 'cashSign2',
  'hubly-dashboard.sidebar.tools.mary-kay.name.member': 'app',
};

const NavSidebarMenuItem: React.FC<NavSidebarMenuItemWithSubItemI> = item => {
  const history = useHistory();
  const isOrganizationAdmin = useIsOrganizationAdmin();
  const {
    selectedItemMenuId,
    setSelectedItemMenuId,
    setSelectedSubItemMenuId,
    selectedSubItemMenuId,
    openedNavigationMenuMobile,
    setOpenedNavigationMenuMobile,
  } = useNavigationMenuContext();

  const { currentOrganization } = useAuthContext();

  const itemActive = selectedItemMenuId === item.id;

  item.subItems.forEach(subItems => {
    if (selectedSubItemMenuId === subItems.id) {
      setSelectedSubItemMenuId(subItems.id);
    }
  });

  const navigate = (path: string): void => {
    if (window.location.pathname === path) return;
    else history.push(path);
  };

  const handleClickItem = (_item: NavSidebarMenuItemWithSubItemI): void => {
    if (_item.subItems && _item.id === selectedItemMenuId) {
      setSelectedItemMenuId(NavigationMenuContextInitialValues.selectedItemMenuId);
    } else {
      setSelectedItemMenuId(_item.id);
      setSelectedSubItemMenuId('');
    }

    if (_item.path && !_item.subItems?.length) {
      navigate(_item.path);
      setOpenedNavigationMenuMobile(!openedNavigationMenuMobile);
    }
  };

  const handleClickSubItem = (
    parentItem: NavSidebarMenuItemWithSubItemI,
    subItem: NavSidebarMenuItemI,
    organization: Organization,
  ): void => {
    setSelectedItemMenuId(parentItem.id);
    setSelectedSubItemMenuId(subItem.id);
    setOpenedNavigationMenuMobile(!openedNavigationMenuMobile);

    if (subItem.path && !parentItem.path) navigate(subItem.path);
  };

  const campaignItem = {
    id: 'hubly-dashboard.sidebar.tools.campaign',
    text: 'Cupons',
    path: `/org/${currentOrganization.slug}/affiliate/campaign`,
    disabled: false,
  };

  const enhancedItem = {
    ...item,
    subItems:
      item.id === 'hubly-dashboard.sidebar.sale.name'
        ? [
            ...(item.subItems ?? []),
            // TODO: remove
            ...(ALLOWED_DOMAINS_CAMPAIGN.includes(window.location.hostname) ? [campaignItem] : []),
          ]
        : item.subItems,
  };

  return (
    <>
      {/* {console.log({ item: enhancedItem })} */}
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger>
            <MenuItem
              onClick={(): void => (enhancedItem.disabled ? null : handleClickItem(enhancedItem))}
              activeHover={itemActive}
              disabled={enhancedItem.disabled}
            >
              <Flex alignItems="center" style={{ gap: '16px' }}>
                {enhancedItem.iconId && <Icon size="SM" gray iconId={enhancedItem.iconId} />}
                <Typography color="neutralLow" size="XXS" fontWeight={500}>
                  {enhancedItem.text}
                </Typography>
              </Flex>
              {(enhancedItem.subItems?.length ?? 0) > 0 && !enhancedItem.disabled && (
                <Icon
                  size="XS"
                  iconId={itemActive ? 'chevronUp' : 'chevronDown'}
                  stroke="neutralLowMedium"
                />
              )}
            </MenuItem>
          </Tooltip.Trigger>

          {enhancedItem.disabled && isOrganizationAdmin && (
            <Tooltip.Portal>
              <Tooltip.Content align={'center'} side="bottom" style={{ zIndex: '10' }}>
                Regularize a sua assinatura para ter acesso a essa funcionalidade.
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          )}
        </Tooltip.Root>
      </Tooltip.Provider>

      {(enhancedItem.subItems?.length ?? 0) > 0 && (
        <MenuSubItemContainer parentIsSelected={itemActive}>
          {enhancedItem.subItems.map(subItem => {
            return (
              <Tooltip.Provider key={subItem.path}>
                <Tooltip.Root>
                  <Tooltip.Trigger className="nav-sidebar-subitem">
                    <MenuSubItem
                      key={`nav-sidebar-subitem-menu-${subItem.id}`}
                      onClick={(): void =>
                        enhancedItem.disabled || subItem.disabled
                          ? null
                          : handleClickSubItem(enhancedItem, subItem, currentOrganization)
                      }
                      disabled={subItem.disabled}
                      active={subItem.id === selectedSubItemMenuId}
                    >
                      <MenuSubItemLine />
                      <Typography color="neutralLow" size="XXXS" lineHeight="MD" fontWeight={500}>
                        {subItem.text}
                      </Typography>
                    </MenuSubItem>
                  </Tooltip.Trigger>
                  {subItem.disabled && !isOrganizationAdmin && subItem.path.includes('showcase') && (
                    <Tooltip.Portal>
                      <Tooltip.Content
                        align={'center'}
                        side="bottom"
                        style={{ zIndex: 10, maxWidth: '180px', padding: '8px' }}
                      >
                        Seu administrador ainda não liberou essa funcionalidade
                        <Tooltip.Arrow />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  )}
                </Tooltip.Root>
              </Tooltip.Provider>
            );
          })}
        </MenuSubItemContainer>
      )}
    </>
  );
};

const NavSidebarMenuItemList = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, loading, refetch } = useQuery<MenuTreeQuery, MenuTreeVariables>(MENU_TREE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const { items, setItems, loadingMenu, setLoadingMenu } = useNavigationMenuContext();

  useEffect(() => {
    if (!items.length && data?.menuTree && !loadingMenu) {
      setItems(data?.menuTree);
    }
  }, [data, items.length, loadingMenu, setItems]);

  useEffect(() => {
    OrganizationStore.subscribe(
      state => state.organizationId,
      organizationId => {
        if (organizationId) {
          refetch({
            input: {
              organizationId,
            },
          });
        }
      },
    );
  }, [refetch]);

  useEffect(() => {
    setLoadingMenu(loading);
  }, [loading, setLoadingMenu]);

  const getMenuSubItems = (children: MenuTreeItem[]): NavSidebarMenuItemI[] => {
    if ((children?.length ?? 0) > 0) {
      return children.map(item => ({
        id: item.name,
        text: t(item.name),
        path: item.slug,
        disabled: item.disabled,
      }));
    }

    return [];
  };

  if (loadingMenu || !items.length) return <Skeleton count={6} style={{ margin: '.5rem 0' }} />;

  return (
    <Flex flexDirection="column">
      {items.length > 0 &&
        items.map(item => {
          const translated = t(item.name);
          return (
            <NavSidebarMenuItem
              id={item.name}
              text={translated}
              path={item.slug}
              subItems={getMenuSubItems(item.children)}
              iconId={ICONS_RELATION[item.name]}
              disabled={item.disabled}
              key={`nav-sidebar-item-menu-${translated}`}
            />
          );
        })}
    </Flex>
  );
};

export default NavSidebarMenuItemList;
