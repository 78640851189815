import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import cookie from 'js-cookie';
import {
  AUTH_URL,
  CAMPAIGNS_URL,
  CART_MODULE_ENDPOINT,
  CATALOG_MODULE_ENDPOINT,
  CUSTOMER_MODULE_ENDPOINT,
  GRAPHQL_ENDPOINT,
  MULTILEVEL_APP_URL,
  ORDERS_URL,
} from '@plug/config/env';
import { createUploadLink } from 'apollo-upload-client';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import {
  TOKEN_LOCAL_STORAGE_KEY,
  APOLLO_AUTH_HEADER_KEY,
  APOLLO_FETCH_POLICY,
  ORGANIZATION_RULES_PERMISSION_STORAGE_KEY,
} from '@plug/config/consts';
import getCurrentOrganization from '@plug/helpers/get-current-organization';
import { resetUserCookies } from '@plug/domains/auth/helpers/session';
import { toast } from 'react-toastify';

const configAuthLink = (): ApolloLink =>
  setContext((data, { headers }) => {
    const token = cookie.get(TOKEN_LOCAL_STORAGE_KEY);
    const authHeader = token !== undefined ? { [APOLLO_AUTH_HEADER_KEY]: token } : {};
    const checkoutHeader = token !== undefined ? { 'x-api-key': token } : {};
    const currentOrganization = getCurrentOrganization();

    const operationFilter = ['userPermission', 'setCurrentOrganizationReturnInfos'];

    return {
      headers: {
        ...headers,
        ...authHeader,
        ...checkoutHeader,
        ...(!operationFilter.includes(data.operationName) &&
          currentOrganization && { 'x-org': currentOrganization?.id }),
        'Access-Control-Allow-Origin': '*',
      },
    };
  });

const configLangLink = (): ApolloLink =>
  setContext((_, { headers }) => {
    const language = localStorage.getItem('locale') ?? 'pt-br';

    return {
      headers: {
        ...headers,
        'Content-Language': language,
      },
    };
  });

const configErrorLink = (): ApolloLink =>
  onError(err => {
    if (err?.graphQLErrors?.[0]?.message?.includes('invalid_organization_session')) {
      if (localStorage.getItem(ORGANIZATION_RULES_PERMISSION_STORAGE_KEY)) {
        alert(
          'Detectamos que sua conta foi acessada em outro dispositivo. Iremos te deslogar por segurança',
        );
      }

      resetUserCookies();

      window.location.href = '/';
    }

    const gqlErrors = err.graphQLErrors
      ?.map(({ message, path }) => `[GraphQLError]: ${message}. Path: ${path}`)
      .join(' | ');
    const networkErrors = err.networkError && `[NetworkError]: ${err.networkError}`;
    const message = `${gqlErrors} ${networkErrors}`;
    //TODO: Insert your error logging logic here
    console.warn(message);
  });

const endpointsLink = () =>
  new MultiAPILink({
    defaultEndpoint: 'base',
    endpoints: {
      base: GRAPHQL_ENDPOINT,
      customer: CUSTOMER_MODULE_ENDPOINT,
      catalog: CATALOG_MODULE_ENDPOINT,
      cart: CART_MODULE_ENDPOINT,
      multilevel: MULTILEVEL_APP_URL,
      auth: AUTH_URL,
      orders: ORDERS_URL,
      campaigns: CAMPAIGNS_URL,
    },
    // @ts-ignore
    createHttpLink: createUploadLink,
  });

export const getApolloClient = async (): Promise<ApolloClient<NormalizedCacheObject>> => {
  const httpLink = endpointsLink();
  const authLink = configAuthLink();
  const errorLink = configErrorLink();
  const langLink = configLangLink();

  // const cache = await configCache();

  const cache = new InMemoryCache();

  const link = ApolloLink.from([langLink, authLink, errorLink, httpLink]);

  return new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: APOLLO_FETCH_POLICY,
      },
    },
  });
};

export type ApolloClientType = ApolloClient<unknown>;