import { Flex } from 'rebass';
import styled, { keyframes } from 'styled-components';
import {
  NAV_SIDEBAR_HEIGHT,
  MENU_HEIGHT_MOBILE,
  NAV_SIDEBAR_WIDTH,
  NAV_SIDEBAR_HEIGHT_MOBILE,
} from './dashboard';
import { ComponentProps } from 'react';

const ContainerAnimation = keyframes`
  0% {
    right: 0vw;
  }

  100% {
    right: -200vw;
  }
`;

export const NavSidebarWrapper = styled.div<
  ComponentProps<'div'> & { isOpen?: boolean; isNewShowcase?: boolean }
>`
  width: ${({ isNewShowcase }): string => (isNewShowcase ? '76px' : NAV_SIDEBAR_WIDTH)};

  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    position: fixed;
    transform: ${({ isOpen }): string => `translateX(${isOpen ? '0' : '-130%'})`};
    transition: transform 0.3s ease-out;
    z-index: 2;
  }
`;

export const FloatingNavSidebar = styled.div<
  ComponentProps<'div'> & { isOpen?: boolean; isNewShowcase?: boolean }
>`
  position: fixed;
  left: 0;
  top: ${(): string => NAV_SIDEBAR_HEIGHT};

  height: auto;
  width: ${({ isNewShowcase }): string =>
    isNewShowcase ? '44px' : `calc(${NAV_SIDEBAR_WIDTH} - 16px)`};

  background: ${({ theme }): string => theme.colors.white};

  z-index: 2;
  margin: 16px;
  margin-right: ${({ isNewShowcase }): string => (isNewShowcase ? '16px' : `0`)};
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    top: ${(): string => NAV_SIDEBAR_HEIGHT_MOBILE};
    height: ${(): string => `calc(100vh - ${MENU_HEIGHT_MOBILE} - 40px)`};
    width: calc(100% - 16px);
    margin: 8px;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  height: ${(): string => NAV_SIDEBAR_HEIGHT};
`;

export const FloatingHeader = styled.div<
  ComponentProps<'div'> & { fluid?: boolean; showMenu?: boolean }
>`
  position: fixed;
  top: 0 !important;
  right: 0;
  width: ${({ fluid }): string => (fluid ? '100%' : 'calc(100% - 243px)')};
  background: ${({ showMenu }): string => (showMenu ? 'rgba(0, 0, 0, 0.1)' : '#f1f2f9')};

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  z-index: 5;
`;

export const AnimatedContainer = styled.div`
  z-index: 22;

  position: absolute;
  height: 100%;

  right: 0vw;
  top: 0px;

  background: linear-gradient(90deg, #fff 50%, #fff 50%);

  animation: ${ContainerAnimation} 2s;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
`;

export const DashboardFlex = styled(Flex)`
  overflow-x: hidden;
  flex: 1;
  flex-direction: column;
  background-color: #f1f2f9;

  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;
