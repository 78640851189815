import i18n from 'i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE, ENVIRONMENT, SUPPORTED_LANGUAGES } from '../config/env';
import moment from 'moment';
import { DOMAIN, MARY_KAY_WHITELABEL } from '@plug/config/consts';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'translation',
      ...(ENVIRONMENT === 'staging' && !MARY_KAY_WHITELABEL.includes(DOMAIN)
        ? []
        : [window.location.hostname]),
      'link-generator',
      'coupon',
      'hubly-form',
      'inside-sales',
      'members',
      'orders',
      'digital-showcase',
      'commission',
      'settings',
      'hubly-cluster',
    ],
    defaultNS: 'translation',
    supportedLngs: SUPPORTED_LANGUAGES.map(({ i18n }) => i18n),
    fallbackLng: 'pt-BR', //DEFAULT_LANGUAGE,
    debug: ENVIRONMENT === 'staging',
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
  });

export default i18n;
